import React from "react"
import { FaLocationDot } from "react-icons/fa6"
import { IoCallSharp, IoMail } from "react-icons/io5"
import { MdKeyboardArrowRight } from "react-icons/md"
import {
    TiSocialFacebookCircular,
    TiSocialTwitterCircular,
    TiSocialLinkedinCircular,
    TiSocialInstagramCircular,
} from "react-icons/ti"
function Footer() {
    return (
        <footer className="flex flex-col bg-[#181818] py-5">
            <div className="container p-6 ">
                <div className="flex justify-around gap-10">
                    <div className="mb-6 h-full">
                        <h5 className="mb-2.5 text-2xl font-bold text-[#DFB163]">Get In Touch</h5>

                        <ul className="mb-0 list-none">
                            <li>
                                <a href="#!" className="flex py-2 items-center text-neutral-200">
                                    <FaLocationDot />
                                    <span className="pl-2">
                                        6952 Sonny Dale Dr, STE &apos;B&apos;, Melbourne, FL 32904
                                    </span>
                                </a>
                            </li>
                            <li>
                                <a href="#!" className="flex py-2 items-center text-neutral-200">
                                    <IoCallSharp />
                                    <span className="pl-2">+1 321-426-7585</span>
                                </a>
                            </li>
                            <li>
                                <a href="#!" className="flex py-2 items-center text-neutral-200">
                                    <IoMail />
                                    <span className="pl-2">Sales@spacecoastdoor.com</span>
                                </a>
                            </li>
                            <li>
                                <div className="flex py-2 w-[3/6] text-white text-4xl">
                                    <a
                                        href="https://www.facebook.com/SCTrimandDoor?mibextid=ZbWKwL"
                                        rel="noreferrer"
                                        target="_blank"
                                    >
                                        <TiSocialFacebookCircular className="mx-1" />
                                    </a>
                                    <a
                                        href="https://www.linkedin.com/company/space-coast-trim-&-door/"
                                        rel="noreferrer"
                                        target="_blank"
                                    >
                                        <TiSocialLinkedinCircular className="mx-1" />
                                    </a>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <div className="mb-6 h-full">
                        <h5 className="mb-2.5 text-2xl font-bold text-[#DFB163]">Quick Links</h5>

                        <ul className="mb-0 list-none">
                            <li>
                                <a href="/" className="flex py-2 items-center text-neutral-200">
                                    <MdKeyboardArrowRight />
                                    <span className="pl-2">Home</span>
                                </a>
                            </li>
                            <li>
                                <a href="/about" className="flex py-2 items-center text-neutral-200">
                                    <MdKeyboardArrowRight />
                                    <span className="pl-2">About Us</span>
                                </a>
                            </li>
                            <li>
                                <a href="/gallery" className="flex py-2 items-center text-neutral-200">
                                    <MdKeyboardArrowRight />
                                    <span className="pl-2">Gallery</span>
                                </a>
                            </li>
                            <li>
                                <a href="/products" className="flex py-2 items-center text-neutral-200">
                                    <MdKeyboardArrowRight />
                                    <span className="pl-2">Our Projects</span>
                                </a>
                            </li>
                            <li>
                                <a href="/contact" className="flex py-2 items-center text-neutral-200">
                                    <MdKeyboardArrowRight />
                                    <span className="pl-2">Contact Us</span>
                                </a>
                            </li>
                        </ul>
                    </div>

                    {/* <div className="mb-6 h-full">
                        <h5 className="mb-2.5 text-2xl font-bold text-[#DFB163]">Popular Links</h5>

                        <ul className="mb-0 list-none">
                            <li>
                                <a href="/" className="flex py-2 items-center text-neutral-200">
                                    <MdKeyboardArrowRight />
                                    <span className="pl-2">Home</span>
                                </a>
                            </li>
                            <li>
                                <a href="/about" className="flex py-2 items-center text-neutral-200">
                                    <MdKeyboardArrowRight />
                                    <span className="pl-2">About Us</span>
                                </a>
                            </li>
                            <li>
                                <a href="/gallery" className="flex py-2 items-center text-neutral-200">
                                    <MdKeyboardArrowRight />
                                    <span className="pl-2">Gallery</span>
                                </a>
                            </li>
                            <li>
                                <a href="/products" className="flex py-2 items-center text-neutral-200">
                                    <MdKeyboardArrowRight />
                                    <span className="pl-2">Our Projects</span>
                                </a>
                            </li>
                            <li>
                                <a href="/contact" className="flex py-2 items-center text-neutral-200">
                                    <MdKeyboardArrowRight />
                                    <span className="pl-2">Contact Us</span>
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div className="mb-6 h-full w-5/6">
                        <h5 className="mb-2.5 text-2xl font-bold text-[#DFB163]">NewsLetter</h5>
                        <div className="flex flex-col w-full">
                            <input className="bg-white my-4 py-2 px-3" placeholder="Enter Your Name" />
                            <input className="bg-white my-4 py-2 px-3" placeholder="Enter Your Mail" />
                            <button
                                className="my-4 py-2 px-3 text-black tracking-widest text-xl
                             bg-[#DFB163] hover:bg-yellow-500"
                            >
                                Submit
                            </button>
                        </div>
                    </div> */}
                </div>
            </div>

            <div className="w-full bg-neutral-700 p-4 text-center text-neutral-200">
                <p>
                    © 2024 Copyright
                    <a className="text-neutral-400 pl-2" href="https://www.spacecoasttnd.com">
                        Space Coast Trim & Door
                    </a>
                </p>
                <p>
                    Designed By
                    <a className="text-neutral-400 pl-2" href="https://www.datumailabs.com/">
                        Datum AI Labs
                    </a>
                </p>
            </div>
        </footer>
    )
}

export default Footer
