import banner from "../assets/home/banner.jpg"
import interior from "../assets/home/interior.jpg"
import exterior from "../assets/home/exterior.jpg"
import trim from "../assets/home/trim.png"
import product1 from "../assets/home/product1.jpg"
import product2 from "../assets/home/product2.jpg"
import product3 from "../assets/home/product3.jpg"
function Home() {
    return (
        <div className="flex justify-center bg-[#f1f0eb] ">
            <div className=" w-[60%]">
                <div>
                    <img src={banner} alt="banner" />
                    <div className="bg-[#DFB163] text-white text-center p-3 italic">
                        <p>
                            WE SPECIALIZE IN INTERIOR AND EXTERIOR DOORS, AS WELL AS BI-FOLDS,TRIM, MOULDING, STAIR
                            PARTS, INSERTS, DECKING, HARDWARE AND MORE.
                        </p>
                    </div>
                </div>

                <div className="p-5 my-5 grid grid-cols-3 gap-3">
                    <div className="flex flex-col items-center text-center border-r border-gray-500 pr-3">
                        <p className="my-3 text-lg"> INTERIOR DOORS</p>
                        <img src={interior} alt="interior" className="my-4" />
                        <p className="text-sm">
                            WE OFFER A WIDE VARIETY OF INTERIOR DOORS TO MEET ANY NEED IMAGINABLE!
                        </p>
                    </div>
                    <div className="flex flex-col items-center text-center border-r border-gray-500 pr-3">
                        <p className="my-3 text-lg"> EXTERIOR DOORS</p>
                        <img src={exterior} alt="exterior" className="my-4" />
                        <p className="text-sm">
                            EXTERIOR DOORS ARE OUR SPECIALTY, PROVIDING YOU AND YOUR FAMILY WITH UNPARALELLED AESTHETICS
                            AND SECURITY!
                        </p>
                    </div>
                    <div className="flex flex-col items-center text-center">
                        <p className="my-3 text-lg"> TRIM & MOULDING</p>
                        <img src={trim} alt="trim" className="my-4" />
                        <p className="text-sm">
                            WITH HUNDREDS OF STYLES OF TRIM AND MOULDING AVAILABLE, WE ARE AN INDUSTRY LEADER!
                        </p>
                    </div>
                </div>

                <div className="flex flex-col items-center">
                    <p className="italic text-center text-2xl">
                        QUALITY PRODUCTS, SERVICE & PRICING Guaranteed: 321.426.7585
                    </p>

                    <div className="my-5 w-[70%] grid grid-cols-3 gap-3">
                        <a href="http://www.nahb.org" rel="noreferrer" target="_blank">
                            <img src={product1} alt="product" />
                        </a>
                        <a href="http://www.fhba.com" rel="noreferrer" target="_blank">
                            <img src={product2} alt="product" />
                        </a>
                        <a href="http://www.hbca-brevard.org" rel="noreferrer" target="_blank">
                            <img src={product3} alt="product" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home
