import React from "react"
import aboutimage from "../assets/about/aboutimage.png"

function About() {
    return (
        <div className="flex flex-col w-full items-center justify-center bg-[#f1f0eb] text-black p-5">
            <div className="md:w-[70%] lg:w-[50%]">
                <div className="p-2">
                    <p className="text-lg font-bold">OUR CORE VALUES:</p>
                    <ul className="text-md">
                        <li>Top-Notch Quality</li>
                        <li>Superior Customer service</li>
                        <li>Systematic & Process Driven</li>
                        <li>Integrity</li>
                        <li>Win-Win</li>
                    </ul>
                </div>
                <div className="p-2">
                    <p className="text-lg font-bold">OUR MISSION:</p>
                    <p className="text-md">
                        Combining our industry experience & technology, deliver high quality products and provide
                        excellent customer service to empower customers to have a world-class experience and be a leader
                        in Central & Northern Florida.
                    </p>
                </div>
                <div className="p-2">
                    <p className="text-lg font-bold">OUR VISION:</p>
                    <p className="text-md">
                        Set a new standard for quality & service for builders, contractors and consumers that raises the
                        bar when it comes to executive and superior customer service, industry knowledge, work ethics,
                        process and system-driven workmanship.
                    </p>
                </div>

                <div className="p-2">
                    <p className="text-lg font-bold">AREA SERVED:</p>
                    <p className="font-semibold pt-2">Brevard County:</p>
                    <ul>
                        <li>Cities</li>
                        <li>Cape Canaveral</li>
                        <li>Cocoa</li>
                        <li>Cocoa Beach</li>
                        <li>Grant</li>
                        <li>Indialantic</li>
                        <li>Malabar</li>
                        <li>Melbourne</li>
                        <li>Melbourne Beach</li>
                        <li>Merritt Island</li>
                        <li>Mims</li>
                        <li>Palm Bay</li>
                        <li>Rockledge</li>
                        <li>Satellite Beach</li>
                        <li>Scottsmoor</li>
                        <li>Sebastian</li>
                        <li>Sharpes</li>
                        <li>Titusville</li>
                    </ul>
                    <p className="font-semibold pt-2">Orange County:</p>
                    <ul>
                        <li>Apopka</li>
                        <li>Christmas</li>
                        <li>Clarcona</li>
                        <li>Gothav</li>
                        <li>Killarney</li>
                        <li>Maitland</li>
                        <li>Oakland</li>
                        <li>Ocoee</li>
                        <li>Orlando</li>
                        <li>Plymouth</li>
                        <li>Tangerine</li>
                        <li>Windermere</li>
                        <li>Winter Garden</li>
                        <li>Winter Park</li>
                        <li>Zellwood</li>
                    </ul>
                    <p className="font-semibold pt-2">Seminole County:</p>
                    <ul>
                        <li>Altamonte Springs</li>
                        <li>Casselberry</li>
                        <li>Geneva</li>
                        <li>Goldenrod</li>
                        <li>Lake Mary</li>
                        <li>Lake Monroe</li>
                        <li>Longwood</li>
                        <li>Oviedo</li>
                        <li>Sanford</li>
                        <li>Winter Springs</li>
                    </ul>
                </div>

                <img src={aboutimage} alt="about Image" />
            </div>
        </div>
    )
}

export default About
