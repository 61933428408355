function Careers() {
    return (
        <div className="flex justify-center bg-[#f1f0eb]">
            <div className="flex flex-col text-md gap-5 w-[90%] md:w-[60%] p-10">
                <p>
                    Space Coast Trim & Doors is one of the largest Doors, Trims and Milwork & materials supplier that
                    manufactures, distributes and installs: Doors, Trims and Locks to both single family as well as
                    multi-family home builders or contractors or suppliers. Located in West Melbourne our core principle
                    is providing quality products and excellent customer service. Our mission is to have a safe
                    workplace for all our employees to put them in a position to succeed and excel in their job.
                </p>
                <p>
                    We continually seek to hire qualified individuals for a wide variety of positions including Sales,
                    Manufacturing, Drivers, and Warehouse. We offer competitive salaries; employer-sponsored Teledoc,
                    vacation and paid time off (PTO) benefits. With comprehensive training provided, industry specific
                    experience is not always necessary.
                </p>
                <p>
                    Space Coast Trim & Doors promotes a safe and drug-free work environment and all applicants are
                    screened before being offered employment.
                </p>
                <p>
                    To apply, download the employment applications below and email them to{" "}
                    <a href="mailto:hr@SpaceCoastDoor.com" className="text-blue-500">
                        hr@SpaceCoastDoor.com
                    </a>
                    .
                </p>
            </div>
        </div>
    )
}

export default Careers
