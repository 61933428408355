import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from "react-router-dom"
import Layout from "./Layout"
import { Gallery, Home, About, Contact, Product, Customer, Careers, Project } from "./pages"

const route = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="gallery" element={<Gallery />} />
            <Route path="about" element={<About />} />
            <Route path="contact" element={<Contact />} />
            <Route path="products" element={<Product />} />
            <Route path="customer" element={<Customer />} />
            <Route path="careers" element={<Careers />} />
            <Route path="project" element={<Project />} />
        </Route>
    )
)

const App = () => {
    return <RouterProvider router={route} />
}

export default App
