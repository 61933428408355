import React from "react"
import { CustomerCollage } from "../components"

function Customer() {
    return (
        <div className="flex justify-center bg-[#f1f0eb]">
            <div className="w-[90%] md:w-[60%] p-10">
                <CustomerCollage />
                <div>
                    <p className="text-[#80745b] font-thin text-sm text-center p-5">
                        SPACE COAST TRIM AND DOOR HAS PARTNERED WITH SOME OF THE INDUSTRY&apos;S LEADING MANUFACTURERS,
                        PROVIDING OUR CUSTOMERS WITH THE ULTIMATE IN SELECTION AND QUALITY. CONTACT YOUR LOCAL SCT&D
                        REPRESENTATIVE FOR COMPETITIVE PRICING AND UNPARALELLED SERVICE.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Customer
