import React from "react"
import product1 from "../assets/product/product1.jpeg"
import product2 from "../assets/product/product2.jpeg"
import product3 from "../assets/product/product3.jpeg"
import product4 from "../assets/product/product4.jpeg"
import product5 from "../assets/product/product5.jpeg"
import product6 from "../assets/product/product6.jpeg"
import product7 from "../assets/product/product7.jpeg"
import product8 from "../assets/product/product8.jpeg"
import product9 from "../assets/product/product9.jpeg"
import product10 from "../assets/product/product10.jpeg"
import product11 from "../assets/product/product11.jpeg"
import product12 from "../assets/product/product12.jpeg"

function ProductCollage() {
    return (
        <div className="grid bg-white grid-cols-1 gap-3" >
            <div className="flex flex-row gap-3">
                <div>
                    <a>
                        <img src={product1} alt="product1" />
                    </a>
                </div>
                <div>
                    <a>
                        <img src={product2} alt="product1" />
                    </a>
                </div>
                <div>
                    <a>
                        <img src={product3} alt="product1" />
                    </a>
                </div>
            </div>
            <div className="flex flex-row gap-3">
                <div>
                    <a>
                        <img src={product4} alt="product1" />
                    </a>
                </div>
                <div>
                    <a>
                        <img src={product5} alt="product1" />
                    </a>
                </div>
                <div>
                    <a>
                        <img src={product6} alt="product1" />
                    </a>
                </div>
            </div>
            <div className="flex flex-row gap-3">
                <div>
                    <a>
                        <img src={product7} alt="product1" />
                    </a>
                </div>
                <div>
                    <a>
                        <img src={product8} alt="product1" />
                    </a>
                </div>
            </div>
            <div className="flex items-end flex-row gap-3">
                <div>
                    <a>
                        <img src={product9} alt="product1" />
                    </a>
                </div>
                <div>
                    <a>
                        <img src={product10} alt="product1" />
                    </a>
                </div>
                <div>
                    <a>
                        <img src={product11} alt="product1" />
                    </a>
                </div>
                <div>
                    <a>
                        <img src={product12} alt="product1" />
                    </a>
                </div>
            </div>
        </div>
    )
}

export default ProductCollage
