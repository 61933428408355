import React from "react"
import { BiLogoFacebook, BiLogoTwitter, BiLogoLinkedin, BiLogoInstagram, BiLogoYoutube } from "react-icons/bi"
import { HiOutlineBuildingOffice2 } from "react-icons/hi2"
import { HiOutlineMailOpen } from "react-icons/hi"
import { TbDeviceLandlinePhone } from "react-icons/tb"
import { AiOutlineMenu } from "react-icons/ai"
import logo from "../assets/logo.jpg"

const Header = () => {
    const [navbarOpen, setNavbarOpen] = React.useState(false)
    return (
        <div className="bg-white">
            <div className="bg-[#181818] pb-[1%]">
                <div className="flex justify-center p-5 items-center">
                    <div className="w-4/6">
                        <div className="flex flex-col items-center justify-center lg:flex-row"></div>
                    </div>
                </div>
            </div>

            <div className="flex justify-center bg-gradient-to-b from-[#181818] from-50% to-white to-50%">
                <div className="flex flex-wrap items-center justify-between px-2 py-3 bg-[#252531] mb-3">
                    <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
                        <div className="w-full lg:w-auto relative flex justify-between">
                            <a
                                className="flex justify-center items-center gap-3 text-xl font-bold mr-4 py-2 whitespace-nowrap uppercase text-white"
                                href="/"
                            >
                                <img src={logo} alt="logo" className="w-20 h-20"></img>
                                <p className="text-white">
                                    <span className="text-[#DFB163]">Space Coast Trim & Door</span>
                                </p>
                            </a>
                            <button
                                className="text-white lg:hidden cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block outline-none focus:outline-none"
                                type="button"
                                onClick={() => setNavbarOpen(!navbarOpen)}
                            >
                                <AiOutlineMenu />
                            </button>
                        </div>
                        <div
                            className={"lg:flex flex-grow items-center" + (navbarOpen ? " flex" : " hidden")}
                            id="example-navbar-danger"
                        >
                            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
                                <li className="nav-item">
                                    <a
                                        className="px-3 py-2 flex items-center text-md uppercase font-light leading-snug text-white hover:text-[#DFB163]"
                                        href="/"
                                    >
                                        <span className="ml-2">Home</span>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        className="px-3 py-2 flex items-center text-md uppercase font-light leading-snug text-white hover:text-[#DFB163]"
                                        href="/about"
                                    >
                                        <span className="ml-2">About</span>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        className="px-3 py-2 flex items-center text-md uppercase font-light leading-snug text-white hover:text-[#DFB163]"
                                        href="/products"
                                    >
                                        <span className="ml-2">Product Lines</span>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        className="px-3 py-2 flex items-center text-md uppercase font-light leading-snug text-white hover:text-[#DFB163]"
                                        href="/customer"
                                    >
                                        <span className="ml-2">Customers</span>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        className="px-3 py-2 flex items-center text-md uppercase font-light leading-snug text-white hover:text-[#DFB163]"
                                        href="/project"
                                    >
                                        <span className="ml-2">Projects</span>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        className="px-3 py-2 flex items-center text-md uppercase font-light leading-snug text-white hover:text-[#DFB163]"
                                        href="/gallery"
                                    >
                                        <span className="ml-2">Gallery</span>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        className="px-3 py-2 flex items-center text-md uppercase font-light leading-snug text-white hover:text-[#DFB163]"
                                        href="/careers"
                                    >
                                        <span className="ml-2">Careers</span>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        className="px-3 py-2 flex items-center text-md uppercase font-light leading-snug text-white hover:text-[#DFB163]"
                                        href="/contact"
                                    >
                                        <span className="ml-2">Contact Us</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex pt-[1%] mx-[10%] flex-col justify-around md:flex-row md:space-x-4 bg-white">
                <div className="p-4 m-2 ml-5 items-start">
                    <div className="flex items-center justify-start lg:justify-start">
                        <div className="md:mr-3 text-5xl text-[#DFB163]">
                            <HiOutlineBuildingOffice2 />
                        </div>
                        <div className="flex flex-col">
                            <h5 className="font-bold text-black">Our Office</h5>
                            <p className="m-0 text-[#777777]">
                                6952 Sonny Dale Dr, STE &apos;B&apos;, Melbourne, FL 32904
                            </p>
                        </div>
                    </div>
                </div>
                <div className="p-4 m-2 ml-5 items-center">
                    <div className="flex items-center justify-start lg:justify-start">
                        <div className="md:mr-3 text-5xl text-[#DFB163]">
                            <HiOutlineMailOpen />
                        </div>
                        <div className="flex flex-col">
                            <h5 className="font-bold text-black">Email Us</h5>
                            <p className="m-0 text-[#777777]">Sales@spacecoastdoor.com</p>
                        </div>
                    </div>
                </div>
                <div className="p-4 m-2 ml-5 lg:ml-0 items-center">
                    <div className="flex items-center justify-start lg:justify-start">
                        <div className="md:mr-3 text-5xl text-[#DFB163]">
                            <TbDeviceLandlinePhone />
                        </div>
                        <div className="flex flex-col">
                            <h5 className="font-bold text-black">Call Us</h5>
                            <p className="m-0 text-[#777777]">+1 321-426-7585</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header
