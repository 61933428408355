import React from "react"
import product1 from "../assets/customer/product1.jpg"
import product2 from "../assets/customer/product2.png"
import product3 from "../assets/customer/product3.png"
import product4 from "../assets/customer/product4.png"
import product5 from "../assets/customer/product5.jpg"
import product6 from "../assets/customer/product6.png"
import product7 from "../assets/customer/product7.png"
import product8 from "../assets/customer/product8.jpg"
import product9 from "../assets/customer/product9.png"
import product10 from "../assets/customer/product10.jpg"
import product11 from "../assets/customer/product11.svg"

function ProductCollage() {
    const products = [
        product1,
        product2,
        product3,
        product4,
        product5,
        product6,
        product7,
        product8,
        product9,
        product10,
        product11,
    ]

    return (
        <div className="grid bg-white grid-cols-1 md:grid-cols-3 gap-3">
            {products.map((product, index) => (
                <div key={index} className="w-full bg-white">
                    <a>
                        <img src={product} alt={`product${index + 1}`} className="w-full bg-white" />
                    </a>
                </div>
            ))}
        </div>
    )
}

export default ProductCollage
