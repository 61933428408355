import React from "react"
import project1 from "../assets/project/project1.jpg"
import project2 from "../assets/project/project2.png"
import project3 from "../assets/project/project3.png"
import project4 from "../assets/project/project4.jpg"
import project5 from "../assets/project/project5.png"

function ProjectCollage() {
    const projects = [project1, project2, project3, project4, project5]

    return (
        <div className="grid bg-white grid-cols-1 md:grid-cols-3 gap-3">
            {projects.map((project, index) => (
                <div key={index} className="w-full bg-white">
                    <a>
                        <img src={project} alt={`project${index + 1}`} className="w-full" />
                    </a>
                </div>
            ))}
        </div>
    )
}
export default ProjectCollage
