import React from "react"
import { ProjectCollage } from "../components"

function Project() {
    return (
        <div className="flex justify-center bg-[#f1f0eb]">
            <div className="w-[90%] md:w-[60%] p-10">
                <ProjectCollage />
            </div>
        </div>
    )
}

export default Project
